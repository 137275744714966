<template>
    <div>
        <div :style='{
            "font-size":"8pt",
            "line-height": "1.6",
            "display": "grid",
            "grid-template-columns": "20% 20% 20% 20% 20%",
        }'>
            <div :style='{"display": "flex"}'>
                Ingreso:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%" }'>
                    {{datosInter.dateCreated}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Estancia:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center"}'>
                    {{diasEstancia()}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Servicio:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosInter.servicioNombre}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Cama:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosInter.cama}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                <div :style='{"flex": "60%"}'>Grupo sangíneo:</div>
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px","flex": "40%", "text-align":"center"}'>
                    {{datosPac.demograficosTipoSangre}}
                </span>
            </div>
        </div>

        <div :style='{
            "font-size":"8pt",
            "line-height": "1.6",
            "display": "grid",
            "grid-template-columns": "50% 50%",
        }'>
            <!--
            <div :style='{"display": "flex"}'>
                Escolaridad:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosPac.escolaridadNom}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Ocupación:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    -
                </span>
            </div>


            <div :style='{"display": "flex"}'>
                <div :style='{"flex": "60%"}'>Lengua indígena:</div>
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "flex":"40%", "text-align":"center", "text-align":"center" }'>
                    {{datosPac.etnicoNom}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Religión:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosPac.demiograficosReligionNom}}
                </span>
            </div>
            -->
            <div :style='{"display": "flex"}'>
                Procedencia:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosPac.localidadNom}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Alergias:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    -
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Responsable:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosPac.responsable}}
                </span>
            </div>

            <div :style='{"display": "flex"}'>
                Parentesco:
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosPac.parentescoResponsableNom}}
                </span>
            </div>

        </div>
        <div :style='{
            "font-size":"8pt",
            "line-height": "1.6",
        }'>

            <div :style='{"display": "flex"}'>
                <div  :style='{"flex": "15%"}'>Motivo de ingreso:</div>
                <span :style='{"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" }'>
                    {{datosInter.motivo}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    methods:{
        diasEstancia(){
            let fechaA
            let fechaDe
            let dif
            let text
            if (this.datosInter){
                fechaDe = this.moment(this.datosInter.dateCreated.split(' ')[0]+' 00:00', 'DD/MM/YYYY HH:mm')
                fechaA = this.moment(this.estaFecha+' 00:00', 'DD-MM-YYYY HH:mm')
                dif = fechaA.diff(fechaDe, 'days')
            }
            text = dif+' dias'
            if (dif == 0){
                text = 'Ingresó hoy'
            }
            if (dif == 1){
                text = 'Ingresó ayer'
            }
            return (text)

        },
    },
    props:{
        datosInter:Object,
        datosPac:Object,
        datosTurnos:Object,
    }

}
</script>

<style>

</style>