var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{style:({
        "font-size":"8pt",
        "line-height": "1.6",
        "display": "grid",
        "grid-template-columns": "20% 20% 20% 20% 20%",
    })},[_c('div',{style:({"display": "flex"})},[_vm._v(" Ingreso: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%" })},[_vm._v(" "+_vm._s(_vm.datosInter.dateCreated)+" ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Estancia: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center"})},[_vm._v(" "+_vm._s(_vm.diasEstancia())+" ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Servicio: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosInter.servicioNombre)+" ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Cama: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosInter.cama)+" ")])]),_c('div',{style:({"display": "flex"})},[_c('div',{style:({"flex": "60%"})},[_vm._v("Grupo sangíneo:")]),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px","flex": "40%", "text-align":"center"})},[_vm._v(" "+_vm._s(_vm.datosPac.demograficosTipoSangre)+" ")])])]),_c('div',{style:({
        "font-size":"8pt",
        "line-height": "1.6",
        "display": "grid",
        "grid-template-columns": "50% 50%",
    })},[_c('div',{style:({"display": "flex"})},[_vm._v(" Procedencia: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosPac.localidadNom)+" ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Alergias: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" - ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Responsable: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosPac.responsable)+" ")])]),_c('div',{style:({"display": "flex"})},[_vm._v(" Parentesco: "),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosPac.parentescoResponsableNom)+" ")])])]),_c('div',{style:({
        "font-size":"8pt",
        "line-height": "1.6",
    })},[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"flex": "15%"})},[_vm._v("Motivo de ingreso:")]),_c('span',{style:({"border-bottom": "5px solid black", "border-bottom-width": "thin", "padding":"0px 5px 0px 5px", "width":"100%", "text-align":"center" })},[_vm._v(" "+_vm._s(_vm.datosInter.motivo)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }